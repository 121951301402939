import { observer } from 'mobx-react'
import { Login } from '~components/pages'
import { Grid } from '~components'
import { useRive } from '@rive-app/react-canvas'

const LoginPage = ({ mixpanel }) => {
  const { RiveComponent } = useRive({
    src: '/flowers-alive.riv',
    autoplay: true,
  })

  return (
    <div
      className={
        'w-full md:bg-yellow relative md:pt-28 overflow-hidden md:flex md:justify-center md:flex-col md:pb-20 min-h-screen'
      }
    >
      <div
        className="hidden md:block absolute w-full top-0 left-0 h-[600%] z-0"
        style={{
          background:
            'linear-gradient(0deg, rgba(248, 207, 255, 0) 50%, rgba(248, 207, 255, 0.8) 100%)',
        }}
      ></div>
      <Grid className="md:min-h-[76vh] relative z-10">
        <div
          className={
            'md:col-span-6 md:col-start-4 xl:col-span-4 xl:col-start-5 mt-32 md:mt-0 flex justify-start'
          }
        >
          <div className="md:-mx-[30px] flex-[1_0_100%]">
            <div className="md:bg-white md:rounded-[60px] md:px-10 md:py-[90px] w-full 2xl:max-w-[64rem] flex justify-start flex-col md:h-full md:mb-[8vw]">
              <Login mixpanel={mixpanel} />
            </div>
          </div>
        </div>
      </Grid>
      <div className="absolute bottom-[29px] left-0 w-full h-[24vw] z-20 pointer-events-none hidden md:block">
        <RiveComponent />
      </div>
    </div>
  )
}

export default observer(LoginPage)
